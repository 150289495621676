<template>
  <div class="container">

    <div class="row video-part">
      <div class="col-md-9" v-loading="loading">
        <div class="book-title-header">
          <span class="video-part-title">
            视频列表
          </span>
        </div>
        <div class="video-video-box">

          <div v-show="!isvideos">
            <div class="no-video-text">
              当前分类没有视频
            </div>
          </div>

          <div class="row video-box" v-for="item in videos" :key="item.id">
            <div class="col-md-4">
              <div class="video-image">
                <router-link :to="{ path: '/video/details', query: { id: item.id } }">
                  <img class="card-img-top" :src="'/images/common/image' + item.image_id + '.jpg'" :item="item.title"
                    :alt="item.title" />
                </router-link>
              </div>
            </div>
            <div class="col-md-8">
              <div class="video-title">
                <router-link :to="{ path: '/video/details', query: { id: item.id } }" class="video-title-a">
                  {{ item.title }}
                </router-link>
              </div>
              <div class="video-footer">
                <span class="video-icon-box">
                  <span class="video-view-icon"> <img src="/bootstrap-icons-1.9.1/eye.svg" alt=""></span>
                  <span class="video-icon-text">{{ item.view }}</span>
                  <router-link v-if="item.video_category"
                    :to="{ path: '/video/category/details', query: { id: item.category_id } }" class="video-category">
                    {{ item.video_category.title }}
                  </router-link>
                  <!-- <span class="video-icon"> <img src="/bootstrap-icons-1.9.1/heart.svg" alt=""></span>
                    <span class="video-icon-text">点赞</span>
                    <span class="video-icon"> <img src="/bootstrap-icons-1.9.1/bookmark.svg" alt=""></span>
                    <span class="video-icon-text">收藏</span>
                    <span class="video-icon"> <img src="/bootstrap-icons-1.9.1/share.svg" alt=""></span>
                    <span class="video-icon-text">分享</span> -->
                </span>

                <a v-if="item.video_source" :href="'/video/source/details?mid=' + item.mid" class="video-category right">
                  {{ item.video_source.title }}
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="col-md-3 video-index-right">
        <!-- <nav class="video-search-right-box">
            <form class="form-inline" style="width: 100%;">
              <input class="form-control video-search-right" type="search" placeholder="关键词" aria-label="Search">
              <button class="btn btn-outline-success my-2 my-sm-0 video-search-right-btn" type="submit">搜索</button>
            </form>
          </nav> -->

        <ul class="list-group">
          <li class="list-group-item active" aria-current="true">来源</li>
          <div v-for="cat in videoSources" :key="cat.id">
            <a :href="'/video/source?id=' + cat.id">
              <li class="list-group-item">{{ cat.title }}</li>
            </a>
          </div>

        </ul>
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { isEmpty } from 'element-plus/es/utils'

export default {
  // name: 'Index',
  props: ['user'],
  data() {
    return {
      sliders: null,
      videos: null,
      videoSources: [],
      isvideos: true,
      loading: true,
    }
  },
  created() {
    this.videoList()
    this.videoSourceList()
  },
  methods: {
    async videoList() {
      if (this.$route.query.catId == undefined) {
        this.$route.query.catId = 0
      }
      const response = await axios.post('/api/web/video/list')
      // console.log(response.data.data.data)
      const items = response.data.data.data
      if (isEmpty(response.data.data.data)) {
        this.isvideos = false
      }
      this.videos = items
      this.loading = false
    },
    async videoSourceList() {
      const response = await axios.post('/api/web/video/source/list')
      const items = response.data.data.data
      this.videoSources = items
      this.loading = false
    },
    async video(id) {
      this.$router.push({ path: `/video/${id}` })
    }
  }
}
</script>
